import { Item } from '@wix/ambassador-portfolio-project-items-v1-project-item/types';
import {
  Image,
  Video,
  VideoV2,
} from '@wix/ambassador-portfolio-projects-v1-project/types';

const isExternal = (videoInfo: VideoV2 | undefined) =>
  !videoInfo?.url?.includes('wixstatic.com');

const convertCommonImageToWixCode = (item?: Item) => {
  const link = item?.link
    ? { ...(item?.link ? item.link : {}), type: 'direct' }
    : undefined;
  if (item?.image) {
    const imageInfo = item?.image?.imageInfo;
    return {
      type: 'image',
      title: item?.title || '',
      description: item?.description || '',
      alt: imageInfo?.altText || item?.title,
      // @ts-expect-error
      projectSlug: item.projectSlug,
      // @ts-expect-error
      collectionSlug: item.collectionSlug,
      imageToken: item?.image?.token,
      src: `wix:image://v1/${imageInfo?.filename}/${imageInfo?.filename}#originWidth=${imageInfo?.width}&originHeight=${imageInfo?.height}`,
      settings: item.image.focalPoint
        ? { focalPoint: [item.image.focalPoint.x, item.image.focalPoint.y] }
        : {},
      link,
    };
  } else {
    const videoInfo = item?.video?.videoInfo;
    const videoPoster = videoInfo?.posters && videoInfo?.posters[0];
    const isExternalVideo = isExternal(videoInfo);
    const videoPath = isExternalVideo
      ? videoInfo?.url
      : `wix:video://v1/${videoInfo?.filename}/_#posterUri=${videoPoster?.id}&posterWidth=${videoPoster?.width}&posterHeight=${videoPoster?.height}`;
    return {
      type: 'video',
      title: item?.title || '',
      description: item?.description || '',
      imageToken: '',
      // @ts-expect-error
      projectSlug: item.projectSlug,
      // @ts-expect-error1
      collectionSlug: item.collectionSlug,
      src: `${videoPath}`,
      thumbnail: isExternalVideo
        ? `${videoPoster?.url}_/${videoPoster?.width}_${videoPoster?.height}/`
        : undefined,
      link,
    };
  }
};

export const commonImagesToWixCode = (items: Item[]) => {
  const wixCodeItems = items?.map(convertCommonImageToWixCode);
  return wixCodeItems;
};

export const convertCoverToWixCode = (cover: Image | Video) => {
  if ('imageInfo' in cover) {
    const imageInfo = cover?.imageInfo;
    return {
      type: 'image',
      alt: imageInfo?.altText || '',
      imageToken: cover?.token,
      src: `wix:image://v1/${imageInfo?.filename}/${imageInfo?.filename}#originWidth=${imageInfo?.width}&originHeight=${imageInfo?.height}`,
      settings: cover.focalPoint
        ? { focalPoint: [cover.focalPoint.x, cover.focalPoint.y] }
        : {},
    };
  } else if ('videoInfo' in cover) {
    const videoInfo = cover?.videoInfo;
    const videoPoster = videoInfo?.posters && videoInfo?.posters[0];
    const isExternalVideo = isExternal(videoInfo);
    const videoPath = isExternalVideo
      ? videoInfo?.url
      : `wix:video://v1/${videoInfo?.filename}/_#posterUri=${videoPoster?.id}&posterWidth=${videoPoster?.width}&posterHeight=${videoPoster?.height}`;
    return {
      type: 'video',
      imageToken: '',
      src: `${videoPath}`,
      thumbnail: isExternalVideo
        ? `${videoPoster?.url}_/${videoPoster?.width}_${videoPoster?.height}/`
        : undefined,
    };
  }
};
